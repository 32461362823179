var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        _vm._l(_vm.salesOps, function(item) {
          return _c(
            "el-col",
            { key: item.text, attrs: { span: 12 } },
            [
              _c(
                "el-row",
                {
                  staticClass: "sale-box",
                  attrs: { type: "flex", justify: "space-between" }
                },
                [
                  _c("span", { staticClass: "text" }, [
                    _vm._v(_vm._s(item.text))
                  ]),
                  _c("span", [
                    _vm._v("人均消费：￥" + _vm._s(item.avg_money || 0))
                  ])
                ]
              ),
              _c(
                "el-row",
                { staticClass: "sale-item" },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", [
                      _vm._v("\n            成交量\n            "),
                      _c("span", { staticClass: "num" }, [
                        _vm._v(_vm._s(item.num || 0))
                      ])
                    ]),
                    _c("div")
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", [
                      _vm._v("\n            成交额\n            "),
                      _c("span", { staticClass: "num" }, [
                        _vm._v(_vm._s(item.money || 0))
                      ])
                    ]),
                    _c("div")
                  ])
                ],
                1
              )
            ],
            1
          )
        }),
        1
      ),
      _c("el-row", { staticClass: "box title-box" }, [
        _c("span", { staticClass: "title" }, [_vm._v("待处理统计")])
      ]),
      _c(
        "el-row",
        {
          staticClass: "box wait-box",
          attrs: { type: "flex", justify: "space-around" }
        },
        _vm._l(_vm.waitOps, function(item) {
          return _c("el-col", { key: item.text, attrs: { span: 4 } }, [
            _c(
              "div",
              { staticClass: "wrap" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "div",
                    attrs: {
                      to: {
                        name: "OrderList",
                        params: { status: item.status }
                      },
                      tag: "span"
                    }
                  },
                  [
                    _c("div", { staticClass: "text" }, [
                      _vm._v(_vm._s(item.text))
                    ]),
                    _c("div", { staticClass: "number" }, [
                      _c("span", [_vm._v("(" + _vm._s(item.val) + ")")])
                    ])
                  ]
                )
              ],
              1
            )
          ])
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }