//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import VeLine from "v-charts/lib/line.common";
import { fetchSales, fetchTodos, fetchOrderPerson } from "@/api/sys";
import { fetchOrder } from "@/api/charge";
import countTo from "vue-count-to";
export default {
  components: {
    VeLine: VeLine,
    countTo: countTo
  },
  data: function data() {
    return {
      chartData: {
        columns: ["日期", "订单数	", "人数"],
        rows: []
      },
      salesOps: [{
        text: "今日总额",
        num: 0,
        money: 0,
        avg_money: 0,
        label: "today"
      }, {
        text: "近7天总额",
        num: 0,
        money: 0,
        avg_money: 0,
        label: "last7"
      }, {
        text: "昨日总额",
        num: 0,
        money: 0,
        avg_money: 0,
        label: "yesterday"
      }, {
        text: "近30天总额",
        num: 0,
        money: 0,
        avg_money: 0,
        label: "last30"
      }],
      saleExpire: '',
      waitOps: [{
        text: "待支付",
        val: 0,
        label: "unpaid",
        status: 0
      }, {
        text: "发起充电",
        val: 0,
        label: "wait",
        status: 1
      }, {
        text: "正在充电",
        val: 0,
        label: "charging",
        status: 2
      }, {
        text: "充电完成",
        val: 0,
        label: "finish",
        status: 3
      }, {
        text: "充电失败",
        val: 0,
        label: "fail",
        status: 99
      }]
    };
  },
  created: function created() {
    this.getSales();
    this.getTodos(); // this.getTradeList()
  },
  methods: {
    getSales: function getSales() {
      var _this = this;

      fetchOrder().then(function (res) {
        var data = res.data;
        _this.saleExpire = res.expire;

        _this.transSales1(data, _this.salesOps);
      });
    },
    getTodos: function getTodos() {
      var _this2 = this;

      fetchTodos().then(function (res) {
        var data = res.data;

        _this2.transSales(data, _this2.waitOps);
      });
    },
    getTradeList: function getTradeList() {
      var _this3 = this;

      fetchOrderPerson().then(function (res) {
        var data = res.data;
        console.log(data);

        _this3.transTrade(data);
      }).catch(function (err) {
        console.log(err);
      });
    },
    transTrade: function transTrade(data) {
      var arr = [];
      var txt = this.chartData.columns;

      for (var i = 0; i < data.length; i++) {
        var obj = {};
        obj[txt[0]] = data[i]["hour"];
        obj[txt[1]] = data[i]["order_count"];
        obj[txt[2]] = data[i]["user_count"];
        arr.push(obj);
      }

      this.chartData.rows = arr;
    },
    transSales: function transSales(data, orgin) {
      console.log(data);
      var equOps = orgin;

      for (var i in data) {
        for (var j in equOps) {
          if (i == equOps[j]["label"]) {
            equOps[j]["val"] = data[i];
          }
        }
      }
    },
    transSales1: function transSales1(data, orgin) {
      console.log(data); // num: 0, money: 0, avg_money: 0

      var equOps = orgin;

      for (var i in data) {
        for (var j in equOps) {
          if (i == equOps[j]["label"]) {
            equOps[j]["num"] = data[i]["num"];
            equOps[j]["money"] = data[i]["money"];
            equOps[j]["avg_money"] = data[i]["avg_money"];
          }
        }
      }
    }
  }
};